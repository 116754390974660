import React from 'react';
import { ReactComponent as FleetControlIcon } from '../../assets/public/fleetcontrolIcon.svg';
import { ReactComponent as TrackingIcon } from '../../assets/public/trackingIcon2.svg';
import { ReactComponent as InsightsIcon } from '../../assets/public/insightsIcon.svg';
import { ReactComponent as FleetIcon } from '../../assets/public/fleetIcon.svg';
import { ReactComponent as MaintenanceIcon } from '../../assets/public/maintenanceIcon.svg';
import { ReactComponent as GeofencesIcon } from '../../assets/public/geofencesIcon.svg';
import { ReactComponent as AlertsIcon } from '../../assets/public/alertsIcon.svg';
import { ReactComponent as PartsIcon } from '../../assets/public/partsIcon.svg';
import { ReactComponent as HelpIcon } from '../../assets/public/helpIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/public/settingsIcon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/public/logoutIcon.svg';

type NavigationName = {
  key: string;
  title: string;
  name: string;
  pathName: string;
  url: string;
  gaEventTrackerLabel: string;
  icon: JSX.Element;
};

type NavigationNames = {
  FLEET_CONTROL: NavigationName;
  TRACKING: NavigationName;
  INSIGHT: NavigationName;
  FLEET: NavigationName;
  MAINTENANCE: NavigationName;
  GEOFENCE: NavigationName;
  ALERTS: NavigationName;
  PARTS: NavigationName;
  HELP: NavigationName;
  SETTINGS: NavigationName;
  LOG_OUT: NavigationName;
};

const menuNavigationNames: NavigationNames = {
  FLEET_CONTROL: {
    key: 'FLEET_CONTROL',
    title: 'Fleet Control',
    name: 'fleetControl',
    pathName: 'control',
    url: '/control',
    gaEventTrackerLabel: 'Link',
    icon: <FleetControlIcon />,
  },
  TRACKING: {
    key: 'TRACKING',
    title: 'Tracking',
    name: 'tracking',
    pathName: 'tracking',
    url: '/tracking',
    gaEventTrackerLabel: 'Link',
    icon: <TrackingIcon />,
  },
  INSIGHT: {
    key: 'INSIGHT',
    title: 'Insights',
    name: 'insights',
    pathName: 'quicksight',
    url: '/quicksight',
    gaEventTrackerLabel: 'Link',
    icon: <InsightsIcon />,
  },
  FLEET: {
    key: 'FLEET',
    title: 'Fleet',
    name: 'fleet',
    pathName: 'fleet',
    url: '/fleet/currentFleetStatus',
    gaEventTrackerLabel: 'Link',
    icon: <FleetIcon />,
  },
  MAINTENANCE: {
    key: 'MAINTENANCE',
    title: 'Maintenance',
    name: 'maintenance',
    pathName: 'maintenance',
    url: '/maintenance',
    gaEventTrackerLabel: 'Link',
    icon: <MaintenanceIcon />,
  },
  GEOFENCE: {
    key: 'GEOFENCE',
    title: 'Geofence',
    name: 'geofence',
    pathName: 'geofence',
    url: '/geofence',
    gaEventTrackerLabel: 'Link',
    icon: <GeofencesIcon />,
  },
  ALERTS: {
    key: 'ALERTS',
    title: 'Alerts',
    name: 'alerts',
    pathName: 'alertconfiguration',
    url: '/alertconfiguration',
    gaEventTrackerLabel: 'Link',
    icon: <AlertsIcon />,
  },
  PARTS: {
    key: 'PARTS',
    title: 'Parts',
    name: 'parts',
    pathName: 'parts',
    url: '/parts',
    gaEventTrackerLabel: 'Link',
    icon: <PartsIcon />,
  },
  HELP: {
    key: 'HELP',
    name: 'help',
    title: 'Help',
    pathName: 'help',
    url: '/help/faq',
    gaEventTrackerLabel: 'Link',
    icon: <HelpIcon />,
  },
  SETTINGS: {
    key: 'SETTINGS',
    name: 'settings',
    title: 'Settings',
    pathName: 'settings',
    url: '/settings',
    gaEventTrackerLabel: 'Link',
    icon: <SettingsIcon />,
  },
  LOG_OUT: {
    key: 'LOG_OUT',
    name: 'logout',
    title: 'Log Out',
    pathName: 'logout',
    url: '/logout',
    gaEventTrackerLabel: '',
    icon: <LogoutIcon />,
  },
};

export default menuNavigationNames;
