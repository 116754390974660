import sensorTypeIds from './sensorTypeIds';

export default {
  defaultDrawerSensorTypes: {
    LOCATION: {
      key: 'LOCATION',
      id: '0',
      sensorTypeId: '1',
      sensorTypeName: 'location',
      title: 'Location',
      name: 'gpsLocation',
      icon: 'locationIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: true,
      defaultGoDisplay: true,
      sensorType: sensorTypeIds.gps,
      displayName: 'Location',
      hideable: true,
      activityLogHeight: '29vh',
      queryPath: sensorTypeIds.gps.queryFields,
    },
    ABS: {
      key: 'ABS',
      id: '1',
      sensorTypeId: '7',
      sensorTypeName: 'abs',
      title: 'ABS',
      name: 'brakesStatus',
      icon: 'absIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.absSystemFault,
      displayName: 'ABS',
      hideable: true,
      activityLogHeight: '47vh',
      queryPath: sensorTypeIds.absSystemFault.queryFields,
    },
    TIRE_INFLATION_SYSTEM: {
      key: 'TIRE_INFLATION_SYSTEM',
      id: '2',
      sensorTypeId: '15',
      sensorTypeName: 'tis',
      title: 'Tire Inflation System',
      name: 'tireStatus',
      icon: 'tisIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.tireInflationStatus,
      displayName: 'Tire Inflation System',
      hideable: true,
      activityLogHeight: '59vh',
      queryPath: sensorTypeIds.tireInflationStatus.queryFields,
    },
    LOAD_STATUS: {
      key: 'LOAD_STATUS',
      id: '3',
      sensorTypeId: '5',
      sensorTypeName: 'loadStatus',
      title: 'Load Status',
      name: 'loadStatus',
      icon: 'loadStatusIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.enhancedWeight,
      displayName: 'Load Status',
      hideable: true,
      activityLogHeight: '53vh',
      queryPath: sensorTypeIds.enhancedWeight.queryFields,
    },
    ODOMETER: {
      key: 'ODOMETER',
      id: '4',
      sensorTypeId: '6',
      sensorTypeName: 'odometer',
      title: 'Odometer',
      name: 'odometer',
      icon: 'odometerIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.odometer,
      displayName: 'Odometer',
      hideable: true,
      activityLogHeight: '47vh',
      queryPath: sensorTypeIds.odometer.queryFields,
    },
    DOORS: {
      key: 'DOORS',
      id: '6',
      sensorTypeId: '36',
      sensorTypeName: 'doorStatus',
      title: 'Doors',
      name: 'doorStatus',
      icon: 'doorsIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.door,
      displayName: 'Doors',
      hideable: true,
      activityLogHeight: '59vh',
      queryPath: sensorTypeIds.door.queryFields,
    },
    TETHER_STATUS: {
      key: 'TETHER_STATUS',
      id: '7',
      sensorTypeId: '10',
      sensorTypeName: 'tetherStatus',
      title: 'Tether Status',
      name: 'tetherStatus',
      icon: 'tetherIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: true,
      defaultGoDisplay: true,
      sensorType: sensorTypeIds.tetherChange,
      displayName: 'Tether Status',
      hideable: true,
      activityLogHeight: '53vh',
      queryPath: sensorTypeIds.tetherChange.queryFields,
    },
    LIGHTS: {
      key: 'LIGHTS',
      id: '8',
      sensorTypeId: '8',
      sensorTypeName: 'lightsStatus',
      title: 'Lights',
      name: 'lightsStatus',
      icon: 'lightsIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.lightSystemFault,
      displayName: 'Lights',
      hideable: true,
      activityLogHeight: '30vh',
      queryPath: sensorTypeIds.lightSystemFault.queryFields,
    },
    TRAILER_BATTERY: {
      key: 'TRAILER_BATTERY',
      id: '9',
      sensorTypeId: '13',
      sensorTypeName: 'trailerBattery',
      title: 'Trailer Battery',
      name: 'trailerBattery',
      icon: 'boxBattIcon',
      defaultProDisplay: true,
      defaultBaseDisplay: false,
      defaultGoDisplay: true,
      sensorType: sensorTypeIds.sensorBoxBattery,
      displayName: 'Trailer Battery',
      hideable: true,
      activityLogHeight: '53vh',
      queryPath: sensorTypeIds.sensorBoxBattery.queryFields,
    },
    GPS_MILEAGE: {
      key: 'GPS_MILEAGE',
      id: '10',
      sensorTypeId: '16',
      sensorTypeName: 'gpsMileage',
      title: 'GPS Mileage',
      name: 'gpsMileage',
      icon: 'odometerIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: true,
      defaultGoDisplay: true,
      sensorType: sensorTypeIds.gpsMileage,
      displayName: 'GPS Mileage',
      activityLogHeight: '59vh',
      queryPath: sensorTypeIds.gpsMileage.queryFields,
    },
    TSE: {
      key: 'TSE',
      id: '11',
      sensorTypeId: '21',
      sensorTypeName: 'tseBrakes',
      title: 'Brakes',
      name: 'tseBrakes',
      icon: 'tseIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.tse,
      displayName: 'Brakes',
      activityLogHeight: '41vh',
      queryPath: sensorTypeIds.tse.queryFields,
    },
    BACKUP_BATTERY_VOLTAGE: {
      key: 'BACKUP_BATTERY_VOLTAGE',
      id: '12',
      sensorTypeId: '22',
      sensorTypeName: 'backupBattery',
      title: 'Backup Battery',
      name: 'backupBattery',
      icon: 'boxBattIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: true,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.backupBattery,
      displayName: 'Backup Battery',
      hideable: true,
      activityLogHeight: '59vh',
      queryPath: sensorTypeIds.backupBattery.queryFields,
    },
    WHEEL_END: {
      key: 'WHEEL_END',
      id: '13',
      sensorTypeId: '23',
      sensorTypeName: 'wheelEnd',
      title: 'Wheel End',
      name: 'wheelEnd',
      icon: 'wheelEndIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.wheelEnd,
      displayName: 'Wheel End',
      activityLogHeight: '21.7vh',
      queryPath: sensorTypeIds.wheelEnd.queryFields,
    },
    INDIVIDUAL_TIRE_PRESSURE: {
      key: 'INDIVIDUAL_TIRE_PRESSURE',
      id: '14',
      sensorTypeId: '24',
      sensorTypeName: 'individualTirePressure',
      title: 'Individual Tire Pressure',
      name: 'individualTirePressure',
      icon: 'wheelPressureIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.individualTirePressure,
      displayName: 'Individual Tire Pressure',
      activityLogHeight: '21.7vh',
      queryPath: sensorTypeIds.individualTirePressure.queryFields,
    },
    REEFER_UNIT: {
      key: 'REEFER_UNIT',
      id: '15',
      sensorTypeId: '25',
      sensorTypeName: 'reeferUnit',
      title: 'Reefer Unit',
      name: 'reeferUnit',
      icon: 'reeferUnitIcon',
      defaultDisplay: false,
      sensorType: sensorTypeIds.reeferUnit,
      displayName: 'Reefer Unit',
      groupingSensors: {
        FUEL_LEVEL: {
          key: 'FUEL_LEVEL',
          id: '1001',
          sensorTypeId: '1001',
          sensorTypeName: 'fuelLevel',
          title: 'Fuel Level',
          name: 'fuelLevel',
          icon: 'fuelLevelIcon',
          defaultDisplay: false,
          displayName: 'Fuel Level',
          queryPath: 'reeferFuelLevelDetails',
          sensorType: sensorTypeIds.reeferFuelLevel,
          group: 'Reefer Unit',
        },
        LOGGER_SENSORS: {
          key: 'LOGGER_SENSORS',
          id: '1002',
          sensorTypeId: '1002',
          sensorTypeName: 'loggerSensors',
          title: 'Logger Sensors',
          name: 'loggerSensors',
          icon: 'loggerSensorsIcon',
          defaultDisplay: false,
          displayName: 'Logger Sensors',
          queryPath: 'reeferTemperatureSensorDetails',
          sensorType: sensorTypeIds.reeferTemperatureSensors,
          group: 'Reefer Unit',
        },
        ZONE_INFO: {
          key: 'ZONE_INFO',
          id: '1003',
          sensorTypeId: '1003',
          sensorTypeName: 'zoneInfo',
          title: 'Zone Info',
          name: 'zoneInfo',
          icon: 'zoneInfoIcon',
          defaultDisplay: false,
          displayName: 'Zone Info',
          queryPath: 'reeferZoneTemperatureDetails',
          sensorType: sensorTypeIds.reeferZoneTemperatures,
          group: 'Reefer Unit',
          activityLogHeight: '46vh',
        },
        ENGINE_HOURS: {
          key: 'ENGINE_HOURS',
          id: '1004',
          sensorTypeId: '1004',
          sensorTypeName: 'engineHours',
          title: 'Engine Hours',
          name: 'engineHours',
          icon: 'engineHoursIcon',
          defaultDisplay: false,
          displayName: 'Engine Hours',
          queryPath: 'reeferHoursDetails',
          sensorType: sensorTypeIds.reeferHours,
          group: 'Reefer Unit',
          activityLogHeight: '45vh',
        },
        TK_UNIT_BATTERY_VOLTAGE: {
          key: 'TK_UNIT_BATTERY_VOLTAGE',
          id: '1005',
          sensorTypeId: '1005',
          sensorTypeName: 'unitBatteryVoltage',
          title: 'Unit Battery Voltage',
          name: 'unitBatteryVoltage',
          icon: 'tkUnitBatteryVoltageIcon',
          defaultDisplay: false,
          displayName: 'Unit Battery Voltage',
          queryPath: 'reeferBatteryDetails',
          sensorType: sensorTypeIds.reeferBattery,
          group: 'Reefer Unit',
          activityLogHeight: '59vh',
        },
        TK_FAULT_INFO: {
          key: 'TK_FAULT_INFO',
          id: '1006',
          sensorTypeId: '1006',
          sensorTypeName: 'faultInfo',
          title: 'Fault Info',
          name: 'faultInfo',
          icon: 'faultInfoIcon',
          defaultDisplay: false,
          displayName: 'Fault Info',
          queryPath: 'reeferFaultsDetails',
          sensorType: sensorTypeIds.reeferFaults,
          group: 'Reefer Unit',
          activityLogHeight: '47vh',
        },
        TK_COMMAND_STATUSES: {
          key: 'TK_COMMAND_STATUSES',
          id: '1007',
          sensorTypeId: '1007',
          sensorTypeName: 'commandStatuses',
          title: 'Command Statuses',
          name: 'commandStatuses',
          icon: 'commandStatusesIcon',
          defaultDisplay: false,
          displayName: 'Command Statuses',
          queryPath: sensorTypeIds.reeferUnit.queryFields,
          group: 'Reefer Unit',
          activityLogHeight: '47vh',
        },
      },
    },
    ULTRASONIC_CARGO: {
      key: 'ULTRASONIC_CARGO',
      id: '16',
      sensorTypeId: '26',
      sensorTypeName: 'ultrasonicCargo',
      title: 'Load Status',
      name: 'loadStatus',
      icon: 'loadStatusIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.ultrasonicCargo,
      displayName: 'Load Status',
      hideable: true,
      activityLogHeight: '36.2vh',
      queryPath: sensorTypeIds.ultrasonicCargo.queryFields,
    },
    CARGO_CAMERA: {
      key: 'CARGO_CAMERA',
      id: '17',
      sensorTypeId: '27',
      sensorTypeName: 'cargoCamera',
      title: 'Cargo Camera',
      name: 'cargoCamera',
      icon: 'cameraIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.cargoCamera,
      displayName: 'Cargo Camera',
      hideable: true,
      activityLogHeight: '36.2vh',
      queryPath: sensorTypeIds.cargoCamera.queryFields,
    },
    TIRE_TEMPERATURE: {
      key: 'TIRE_TEMPERATURE',
      id: '18',
      sensorTypeId: '38',
      sensorTypeName: 'tireTemperature',
      title: 'Tire Temperature',
      name: 'tireTemperature',
      icon: 'wheelEndIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.tireTemperature,
      displayName: 'Tire Temperature',
      activityLogHeight: '21.7vh',
      queryPath: sensorTypeIds.tireTemperature.queryFields,
    },
    XT5300_BATTERY_STATUS: {
      key: 'XT5300_BATTERY_STATUS',
      id: '19',
      sensorTypeId: '37',
      sensorTypeName: 'xt5300BatteryStatus',
      title: 'Device Battery',
      name: 'xt5300BatteryStatus',
      icon: 'boxBattIcon',
      defaultProDisplay: false,
      defaultBaseDisplay: false,
      defaultGoDisplay: false,
      sensorType: sensorTypeIds.xt5300BatteryStatus,
      displayName: 'Device Battery',
      activityLogHeight: '21.7vh',
      queryPath: sensorTypeIds.xt5300BatteryStatus.queryFields,
    },
  },
  drawerHeaderSensorTypes: {
    TRACKING: {
      id: '17',
      displayName: 'Tracking',
    },
  },
  drawerFooterSensorTypesGD: {
    NEAREST_SERVICE_CENTER: {
      key: 'NEAREST_SERVICE_CENTER',
      id: '12',
      title: 'Nearest Service Center',
      name: 'nearestServiceCenter',
      displayName: 'Nearest Service Center',
    },
    ACTIVITY_LOG: {
      key: 'ACTIVITY_LOG',
      id: '13',
      title: 'Activity Log',
      name: 'activityLog',
      displayName: 'Activity Log',
      hideable: true,
    },
    PREVENTATIVE_MAINTENANCE: {
      key: 'PREVENTATIVE_MAINTENANCE',
      id: '14',
      title: 'Preventative Maintenance Log',
      name: 'preventativeMaintenance',
      displayName: 'Preventative Maintenance Log',
    },
    RECENT_INSPECTIONS: {
      key: 'RECENT_INSPECTIONS',
      id: '15',
      title: 'Recent Inspections',
      name: 'recentInspections',
      displayName: 'Recent Inspections',
    },
    FLEETPULSE_DEVICE_INFORMATION: {
      key: 'FLEETPULSE_DEVICE_INFORMATION',
      id: '16',
      title: 'FleetPulse Device Information',
      name: 'fleetPulseDeviceInformation',
      displayName: 'FleetPulse Device Information',
    },
  },
  drawerFooterSensorTypesNonGD: {
    ACTIVITY_LOG: {
      key: 'ACTIVITY_LOG',
      id: '13',
      title: 'Activity Log',
      name: 'activityLog',
      displayName: 'Activity Log',
      hideable: true,
    },
    RECENT_INSPECTIONS: {
      key: 'RECENT_INSPECTIONS',
      id: '15',
      title: 'Recent Inspections',
      name: 'recentInspections',
      displayName: 'Recent Inspections',
    },
  },
};
