import { SENSOR_BOX_TYPES } from './deviceTypes';
import sensorReportTypes from './sensorStatusReportTypeGroups';

const sensorTypeIds = {
  gps: {
    id: 1,
    sensor_type_id: '1',
    name: 'GPS',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO, SENSOR_BOX_TYPES.GO],
    receiveActivityLogData: 'Location',
    queryFields: `latitude \nlongitude \nreverseGeoFullAddress \nspeed`,
    key: 'gps',
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=12',
    statusReportTypeGroup: sensorReportTypes.LAST_10_DAYS.name,
  },
  altitude: {
    id: 2,
    sensor_type_id: '2',
    name: 'Altitude',
    key: 'altitude',
  },
  speed: {
    id: 3,
    sensor_type_id: '3',
    name: 'Speed',
    key: 'speed',
  },
  weight: {
    id: 4,
    sensor_type_id: '4',
    name: 'Weight',
    key: 'weight',
  },
  enhancedWeight: {
    id: 5,
    sensor_type_id: '5',
    name: 'Enhanced Weight',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'Load Status',
    queryFields: `loaded \nloadWeight`,
    key: 'enhancedWeight',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=5',
  },
  odometer: {
    id: 6,
    sensor_type_id: '6',
    name: 'Odometer',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'Odometer',
    queryFields: `odometerAbs \nodometerGps`,
    key: 'odometer',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=11',
  },
  absSystemFault: {
    id: 7,
    sensor_type_id: '7',
    name: 'ABS System Fault',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'ABS',
    queryFields: `absFaultDescription \namberLampStatus`,
    key: 'absSystemFault',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=4',
  },
  tetherChange: {
    id: 10,
    sensor_type_id: '10',
    name: 'Tether Change',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'Tether Status',
    queryFields: `tetherStatus`,
    key: 'tetherChange',
    statusReportTypeGroup: sensorReportTypes.LAST_2_DAYS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=10',
  },
  sensorBoxBattery: {
    id: 13,
    sensor_type_id: '13',
    name: 'Sensor Box Battery',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO, SENSOR_BOX_TYPES.GO],
    receiveActivityLogData: 'Trailer Battery',
    queryFields: `batteryVoltageTrailer`,
    key: 'sensorBoxBattery',
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=9',
  },
  door: {
    id: 36,
    sensor_type_id: '36',
    name: 'Door Status',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'Doors',
    queryFields: `doorOpened`,
    key: 'door',
    statusReportTypeGroup: sensorReportTypes.LAST_30_DAYS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=8',
  },
  tireInflationStatus: {
    id: 15,
    sensor_type_id: '15',
    name: 'Tire Inflation Status',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'Tire Inflation System',
    queryFields: `tisStatus`,
    key: 'tireInflationStatus',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=6',
  },
  lightSystemFault: {
    id: 8,
    sensor_type_id: '8',
    name: 'Light System Fault',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    receiveActivityLogData: 'Lights',
    queryFields: `lightRed \nlightGreen \nlightYellow \nlightBlack \nlightBrown`,
    key: 'lightSystemFault',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=7',
  },
  psiDutyCycle: {
    id: 9,
    sensor_type_id: '9',
    name: 'PSI Duty Cycle',
    key: 'psiDutyCycle',
  },
  pms: {
    id: 11,
    sensor_type_id: '11',
    name: 'PMS',
    key: 'pms',
  },
  unknown: {
    id: 12,
    sensor_type_id: '12',
    name: 'Unknown',
    key: 'unknown',
  },
  tractorBattery: {
    id: 14,
    sensor_type_id: '14',
    name: 'Tractor Battery',
    key: 'tractorBattery',
  },
  gpsMileage: {
    id: 16,
    sensor_type_id: '16',
    name: 'GPS Mileage',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.GO],
    receiveActivityLogData: 'GPS Mileage',
    queryFields: `odometerGps`,
    key: 'gpsMileage',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=1',
  },
  amberLampStatus: {
    id: 19,
    name: 'Amber Lamp Status',
    key: 'amberLampStatus',
  },
  tse: {
    id: 21,
    sensor_type_id: '21',
    name: 'Brakes',
    key: 'tse',
    statusReportTypeGroup: sensorReportTypes.WITHIN_LAST_24_HRS.name,
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    queryFields: `tseFaults \nfaultLevel \ntseFaultDetails`,
  },
  backupBattery: {
    id: 22,
    sensor_type_id: '22',
    name: 'Backup Battery Voltage',
    key: 'backupBattery',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    queryFields: `backupBatteryVoltage`,
  },
  wheelEnd: {
    id: 23,
    sensor_type_id: '23',
    name: 'Wheel End',
    key: 'wheelEnd',
    statusReportTypeGroup: sensorReportTypes.LAST_24_HRS.name,
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    queryFields: `wheelEndDetails`,
  },
  individualTirePressure: {
    id: 24,
    sensor_type_id: '24',
    name: 'Individual Tire Pressure',
    key: 'individualTirePressure',
    statusReportTypeGroup: sensorReportTypes.LAST_24_HRS.name,
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    queryFields: `tirePressureDetails`,
  },
  reeferUnit: {
    id: 25,
    sensor_type_id: '25',
    name: 'Reefer Unit',
    key: 'reeferUnit',
    statusReportTypeGroup: sensorReportTypes.LAST_24_HRS.name,
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    queryFields: `thermoKingDetails \nthermoKingFaults {
      id
      code
      color
      description
      operatorAction
    }`,
    troubleshootingLink: 'https://www.thermoking.com/na/en/search.html?q=',
  },
  ultrasonicCargo: {
    id: 26,
    sensor_type_id: '26',
    name: 'Load Status',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.GO],
    queryFields: `loaded`,
    key: 'ultrasonicCargo',
    // troubleshootingLink: 'https://s3.amazonaws.com/fleet-pulse.com/Manuals/Troubleshooting/FleetPulse_Troubleshooting_v11.pdf#page=5',
  },
  cargoCamera: {
    id: 27,
    sensor_type_id: '27',
    name: 'Cargo Camera',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO, SENSOR_BOX_TYPES.GO],
    key: 'cargoCamera',
  },
  reeferFaults: {
    id: 28,
    sensor_type_id: '28',
    name: 'Reefer Faults',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferFaults',
  },
  reeferStatus: {
    id: 29,
    sensor_type_id: '29',
    name: 'Reefer Status',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferStatus',
  },
  reeferBattery: {
    id: 30,
    sensor_type_id: '30',
    name: 'Reefer Battery',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferBattery',
  },
  reeferFuelLevel: {
    id: 31,
    sensor_type_id: '31',
    name: 'Reefer Fuel Level',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferFuelLevel',
  },
  reeferHours: {
    id: 32,
    sensor_type_id: '32',
    name: 'Reefer Hours',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferHours',
  },
  reeferTemperatureSensors: {
    id: 33,
    sensor_type_id: '33',
    name: 'Reefer Temperature Sensors',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferTemperatureSensors',
  },
  reeferZoneTemperatures: {
    id: 34,
    sensor_type_id: '34',
    name: 'Reefer Zone Temperatures',
    drawer: false,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.PRO],
    key: 'reeferZoneTemperatures',
  },
  tireTemperature: {
    id: 38,
    sensor_type_id: '38',
    name: 'Tire Temperature',
    key: 'tireTemperature',
    statusReportTypeGroup: sensorReportTypes.LAST_24_HRS.name,
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.GO],
    queryFields: `tireTemperatureDetails`,
  },
  xt5300BatteryStatus: {
    id: 37,
    sensor_type_id: '37',
    name: 'Device Battery',
    drawer: true,
    sensorBoxMfgrs: [SENSOR_BOX_TYPES.XT5300],
    receiveActivityLogData: 'Device Battery',
    queryFields: `xt5300BatteryStatus`,
    key: 'xt5300BatteryStatus',
  },
};
export default sensorTypeIds;
