import React from 'react';
import './Layout.css';
import VerticalMenu from '../Header/components/VerticalMenu.tsx';
import 'normalize.css';

const Layout = ({ children }) => (
  <div className="layout-root">
    <div className="layout-root-menu">
      <VerticalMenu />
    </div>
    <div className="layout-root-children">{children}</div>
  </div>
);

export default Layout;
