import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import history from '../history';
import LoadingCircle from '../components/LoadingCircle/LoadingCircle';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import PublicRoute from './PublicRoute';
import { findPageTitleByPathname } from '../constants/enums/fpPagesEnum';
import { sendCustomPageViewEvent } from '../utils/gaUtils';
import jwt from 'jsonwebtoken';
import { renewAccessToken } from '../actions/login';
import { FIFTYFIVE_MINUTES_IN_SECONDS, FIVE_MINUTES_IN_SECONDS } from '../constants';

const Login = lazy(() => import('../containers/login'));
const Tracking = lazy(() => import('../containers/tracking/Tracking'));
const QuikcSight = lazy(() => import('../components/QuickSight/QuickSight'));
const Freight = lazy(() => import('../components/Freight/Freight'));
const FreightLink = lazy(() => import('../components/Freight/FreightLink'));
const FAQ = lazy(() => import('../components/FAQ'));
const ForgotPassword = lazy(() => import('../containers/forgot_password/ForgotPassword'));
const ResetPassword = lazy(() => import('../containers/reset_password/ResetPassword'));
const UnsubscribeEmailAlert = lazy(() => import('../containers/unsubscribe/unsubscribe'));
const AdditionalSpecs = lazy(() => import('../components/AdditionalSpecs/components/AdditionalSpecs'));
const Settings = lazy(() => import('../components/Settings/Settings'));
const AbsTroubleshooting = lazy(() => import('../components/AbsTroubleshooting/components/AbsTroubleshooting'));
const CreateAccount = lazy(() => import('../containers/createAccount'));
const SetPassword = lazy(() => import('../containers/setPassword'));
const ConfirmUser = lazy(() => import('../containers/confirmUser/confirmUser'));
const CurrentFleetStatus = lazy(() => import('../components/CurrentFleetStatus/components/CurrentFleetStatus'));
const Geofence = lazy(() => import('../containers/geofence'));
const UserManagement = lazy(() => import('../components/UserManagement/components/UserManagement'));
const TrailerManagement = lazy(() => import('../components/TrailerManagement/TrailerManagement'));
const SubscriptionManagement = lazy(() => import('../components/SubscriptionManagement/SubscriptionManagement'));
const GroupManagement = lazy(() => import('../components/GroupManagement/GroupManagement'));
const CustomerManagement = lazy(() => import('../components/CustomerManagement/CustomerManagement'));
const OtaManagement = lazy(() => import('../components/OTAManagement/OTAManagement'));
const OtaFtpManagement = lazy(() => import('../containers/otaFtpManager'));
const VinTransfer = lazy(() => import('../components/VinTransfer/components/VinTransfer'));
const SingleVinTransfer = lazy(() => import('../components/VinTransfer/components/SingleVinTransfer'));
const AlertManagement = lazy(() => import('../components/AlertManagement/components/AlertManagement'));
const Maintenance = lazy(() => import('../components/Maintenance/Maintenance'));
const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const DeveloperMessageManagement = lazy(() => import('../components/DeveloperMessageManagement/components/DeveloperMessageManagement'));
const LeaseManagement = lazy(() => import('../components/LeaseManagement/LeaseManagement'));
const FleetControl = lazy(() => import('../containers/fleetControl'));

const AppRouter = () => {
  const { isAuthenticated } = useSelector(state => state.login);

  const checkRefreshConditions = useCallback(() => {
    const token = jwt.decode(window.localStorage.getItem('id_token'));
    const currentTime = Math.floor(Date.now() / 1000);
    const lastUserActivityTime = Math.floor(Number(window.localStorage.getItem('lastUserActivityTime')) / 1000);
    const tokenExpireationTime = token?.exp - currentTime;
    const userInactiveTime = currentTime - lastUserActivityTime;
    if (tokenExpireationTime > 0 && tokenExpireationTime < FIVE_MINUTES_IN_SECONDS && userInactiveTime < FIFTYFIVE_MINUTES_IN_SECONDS) {
      renewAccessToken();
    }
  }, []);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      checkRefreshConditions();
    }, 5000);

    return () => clearInterval(timeoutId);
  }, [checkRefreshConditions]);

  useEffect(() => {
    const handleUserActivity = () => {
      window.localStorage.setItem('lastUserActivityTime', Date.now());
    };

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keypress', handleUserActivity);
    document.addEventListener('click', handleUserActivity);
    document.addEventListener('scroll', handleUserActivity);

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keypress', handleUserActivity);
      document.removeEventListener('click', handleUserActivity);
      document.removeEventListener('scroll', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen(location => {
      sendCustomPageViewEvent({ pageName: findPageTitleByPathname(location.pathname), pageUrl: location?.pathname });
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingCircle alignScreenCenter />}>
        <Switch>
          <LoginRoute exact path="/" component={Login} />
          <PrivateRoute path="/tracking/:vin?" isAuthenticated={isAuthenticated} component={Tracking} />
          <PrivateRoute path="/quicksight" isAuthenticated={isAuthenticated} component={QuikcSight} />
          <PrivateRoute path="/help" isAuthenticated={isAuthenticated} component={FAQ} exact />
          <PrivateRoute path="/help/faq" isAuthenticated={isAuthenticated} component={FAQ} />
          <PrivateRoute path="/help/troubleshooting" isAuthenticated={isAuthenticated} component={FAQ} />
          <PrivateRoute path="/help/installation" isAuthenticated={isAuthenticated} component={FAQ} />
          <PrivateRoute path="/freight" isAuthenticated={isAuthenticated} component={Freight} />
          <PrivateRoute path="/freight-link" isAuthenticated={isAuthenticated} component={FreightLink} />
          <PrivateRoute path="/specs/:vin?" component={AdditionalSpecs} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/absTroubleshooting/:vin?" component={AbsTroubleshooting} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/user-management" component={UserManagement} />
          <PrivateRoute path="/trailer-management" component={TrailerManagement} />
          <PrivateRoute path="/subscription-management" component={SubscriptionManagement} />
          <PrivateRoute path="/customer-management" component={CustomerManagement} />
          <PrivateRoute path="/ota-management" component={OtaManagement} />
          <PrivateRoute path="/ota-ftp-management" component={OtaFtpManagement} />
          <PrivateRoute path="/group-management" component={GroupManagement} />
          <PrivateRoute path="/vin-transfer" exact component={VinTransfer} />
          <PrivateRoute path="/vin-transfer/:id" component={SingleVinTransfer} />
          <PrivateRoute path="/fleet/currentFleetStatus" isAuthenticated={isAuthenticated} component={CurrentFleetStatus} />
          <PrivateRoute path="/alertconfiguration" isAuthenticated={isAuthenticated} component={AlertManagement} />
          <PrivateRoute path="/maintenance" isAuthenticated={isAuthenticated} component={Maintenance} />
          <PrivateRoute path="/geofence" isAuthenticated={isAuthenticated} component={Geofence} />
          <PrivateRoute path="/parts/:vin?" component={AdditionalSpecs} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/settings" component={Settings} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/developer-message-management" component={DeveloperMessageManagement} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/lease-management" component={LeaseManagement} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/control" component={FleetControl} isAuthenticated={isAuthenticated} />
          <PublicRoute path="/forgotPassword" component={ForgotPassword} />
          <PublicRoute path="/resetPassword" component={ResetPassword} />
          <PublicRoute path="/unsubscribe" component={UnsubscribeEmailAlert} />
          <PublicRoute path="/setpassword" component={SetPassword} />
          <PublicRoute path="/emailverification" component={ConfirmUser} />
          <PublicRoute path="/createTechnicianAccount" component={CreateAccount} />
          <PublicRoute component={NotFound} exact />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
