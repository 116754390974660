import React from 'react';
import '../styles/VerticalMenu.css';
import { NavLink } from 'react-router-dom';
import menuNavigationNames from '../../../constants/enums/menuNavigationNames';

export interface MenuButton {
  activeScreen?: string;
  pathName?: string;
  url: string;
  target?: string;
  icon?: JSX.Element;
  minimize?: boolean;
  title?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const MenuButton = ({ activeScreen, pathName, url, target, icon, minimize, title, onClick }: MenuButton): JSX.Element => {
  const isActive = activeScreen?.includes(pathName ?? '');

  if (title === menuNavigationNames.LOG_OUT.title) {
    return (
      <div className={isActive ? 'menu-button-active' : 'menu-button'} onClick={onClick}>
        {icon}
        {!minimize && <div className="menu-button-text">{title}</div>}
      </div>
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NavLink to={url} target={target ? target : '_self'} className={isActive ? 'menu-button-active' : 'menu-button'}>
      {icon}
      {!minimize && <div className="menu-button-text">{title}</div>}
    </NavLink>
  );
};

export default MenuButton;
