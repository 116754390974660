import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-tippy/dist/tippy.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ReactGA from 'react-ga4';
import { sendPageViewEvent } from './utils/gaUtils';
import { findPageTitleByPathname } from './constants/enums/fpPagesEnum';

const gaFlagEnabled = process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === 'true';
if (gaFlagEnabled) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

window.REACT_APP_GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
// Build New Relic Configs
window.NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}};
window.NREUM.loader_config={accountID:process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,trustKey:process.env.REACT_APP_NEW_RELIC_TRUST_KEY,agentID:process.env.REACT_APP_NEW_RELIC_AGENT_ID,licenseKey:process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,applicationID:process.env.REACT_APP_NEW_RELIC_APPLICATION_ID};
window.NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,applicationID:process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,sa:1};

sendPageViewEvent({ page: window.location?.pathname, title: findPageTitleByPathname(window.location.pathname) });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
