import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { ReactComponent as MainLogo } from '../../../assets/public/FleetPulse_Logo.svg';
import { ReactComponent as MainLogoWithOutText } from '../../../assets/public/fleetPulseMark2.svg';
import tenantFeatures from '../../../features';
import { fetchVehicleGroupsData } from '../../../actions/vehicleGroups';
import { checkIsUserAuthenticated } from '../../../actions/login';
import { isUserCustomer, roleBasedAuthorization } from '../../../security/authorization';
import { NO_VINS_GROUP } from '../../../constants';
import { shouldHideFAQ } from '../../../utils/faqUtils';
import headerNavigationNames from '../../../constants/enums/headerNavigationNames';
import { PUBLIC_ROUTES } from '../../../constants/enums/pageNames';
import { fleetpulseApiFetch } from '../../../utils/fetch';
import '../styles/VerticalMenu.css';
import { fetchCustomersData } from '../../../actions/customers';
import { fetchFeatureFlags } from '../../../actions/featureToggle';
import { ReactComponent as AccountIcon } from '../../../assets/public/accountIcon.svg';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import MenuButton from './MenuButton';
import menuNavigationNames from '../../../constants/enums/menuNavigationNames';
import { logout } from '../../../actions/logout';

const VerticalMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const features = useSelector((state: any) => state.featureFlag.features);
  const { allTrucks } = useSelector((state: any) => state.tracking);
  const { isAuthenticated, userEmail } = useSelector((state: any) => state.login);
  const vehicleGroups = useSelector((state: any) => state.vehicleGroups);
  const [activeScreen, setActiveScreen] = useState<string>(headerNavigationNames.TRACKING.pathName);
  const [minimize, setMinimize] = useState<boolean>(false);

  const filterNoVinsGroup = vehicleGroups?.vehicleGroupsData?.vehicleGroups.filter((group: { name: string }) => group.name !== NO_VINS_GROUP);
  const noVINsAssigned = _isEmpty(filterNoVinsGroup);
  const publicRoutes = Object.values(PUBLIC_ROUTES).map(route => route.route);
  const isRoutePublic = publicRoutes.includes(activeScreen);
  const initialVinNumber = allTrucks[0]?.vinNumber ?? '';

  useEffect(() => {
    if (userEmail) {
      dispatch(fetchCustomersData());
    }
  }, [dispatch, userEmail]);

  useEffect(() => {
    dispatch(checkIsUserAuthenticated());
    if (isUserCustomer()) {
      const customerId = window.localStorage.getItem('customerId');
      dispatch(fetchVehicleGroupsData(customerId || '', fleetpulseApiFetch));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFeatureFlags());
  }, [dispatch]);

  useEffect(() => {
    setActiveScreen(pathname.replace('/', ''));
  }, [pathname]);

  const verticalMenuStyle = minimize ? 'vertical-menu-minimized' : 'vertical-menu';
  const minimizedLogo = minimize ? <MainLogoWithOutText /> : <MainLogo />;
  const minimizeButton = minimize ? (
    <ChevronRightIcon size={24} onClick={(): void => setMinimize(!minimize)} className="vertical-menu-right" />
  ) : (
    <ChevronLeftIcon size={24} onClick={(): void => setMinimize(!minimize)} className="vertical-menu-left" />
  );

  const onClickLogout = (e: React.MouseEvent): void => {
    e.preventDefault();
    dispatch(logout());
  };

  if (!isAuthenticated || isRoutePublic) {
    return <></>;
  }

  return (
    <div className={verticalMenuStyle}>
      <div className="vertical-menu-header">
        {minimizedLogo}
        {minimizeButton}
      </div>
      <div className="vertical-menu-user">
        <AccountIcon />
        {!minimize && (
          <div className="vertical-menu-user-info">
            {/* <div className="vertical-menu-user-name">NAME</div> */}
            <div className="vertical-menu-user-name">{userEmail}</div>
            {/* <div className="vertical-menu-user-text">{userEmail}</div> */}
            {/* <div className="vertical-menu-user-text">Role</div> */}
            {/* <div className="vertical-menu-user-text">Company Name</div> */}
          </div>
        )}
      </div>
      {features?.FleetControl && <MenuButton activeScreen={activeScreen} {...menuNavigationNames.FLEET_CONTROL} minimize={minimize} />}
      <MenuButton activeScreen={activeScreen} {...menuNavigationNames.TRACKING} minimize={minimize} />
      {roleBasedAuthorization(headerNavigationNames.INSIGHT.name) && features.Insight && (
        <MenuButton activeScreen={activeScreen} {...menuNavigationNames.INSIGHT} minimize={minimize} />
      )}
      {roleBasedAuthorization(headerNavigationNames.FLEET.name) && (
        <MenuButton activeScreen={activeScreen} {...menuNavigationNames.FLEET} minimize={minimize} />
      )}
      {features.MaintenanceDashboard && <MenuButton activeScreen={activeScreen} {...menuNavigationNames.MAINTENANCE} minimize={minimize} />}
      {features.GeofenceV2 && <MenuButton activeScreen={activeScreen} {...menuNavigationNames.GEOFENCE} minimize={minimize} />}
      {roleBasedAuthorization(headerNavigationNames.ALERTS.name) && (
        <MenuButton activeScreen={activeScreen} {...menuNavigationNames.ALERTS} minimize={minimize} />
      )}
      {tenantFeatures.PARTS_HEADER_OPTION && roleBasedAuthorization(headerNavigationNames.PARTS.name) && (
        <MenuButton
          activeScreen={activeScreen}
          {...menuNavigationNames.PARTS}
          minimize={minimize}
          url={`${headerNavigationNames.PARTS.url}/${initialVinNumber}`}
        />
      )}
      {shouldHideFAQ(noVINsAssigned) && <MenuButton activeScreen={activeScreen} {...menuNavigationNames.HELP} minimize={minimize} />}
      <MenuButton activeScreen={activeScreen} {...menuNavigationNames.SETTINGS} minimize={minimize} />
      <MenuButton activeScreen={activeScreen} {...menuNavigationNames.LOG_OUT} minimize={minimize} onClick={onClickLogout} />
    </div>
  );
};

export default VerticalMenu;
